<script>
import { ref } from "vue";
import { mapActions } from "vuex";
import gestioneProdottiProgetto from "@/components/widgets/worksites/gestioneProdottiProgetto";

import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";
import moment from 'moment'
import serverConfig from '@/helpers/config';

import Swal from "sweetalert2";
import axios from 'axios';
import {
    required,
    helpers
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  page: {
    title: "Gestione Forniture e Prodotti",
    meta: [{ name: "description", content: appConfig.description }],
  },
  setup() {
    let files = ref([]);
    moment.locale('it')

    let tk = 'Bearer '+localStorage.getItem('tk')

    return { files, tk, v$: useVuelidate() };
  },
  data() {
    return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        showMainCustomer:false,
        showMainProgect:true,
        showMainSystems:false,
        showMainSurvey:true,
        showMainAdmin:false,
        showMainDocuments:true,
        showAmmProgect:true,
        showTechProgect:true,
        showAdvancedProgect:false,
        title: "Gestione Forniture e Prodotti",
        data:{
          source:'',
          customerCategory:'',
          systemCategory:'',
          systemSubCategory:'',
          fiscalBenefit:'',
          surveyDetails:{
              date:moment.unix(),
              dateStr:moment().format('YYYY-MM-DD'), 
              status: '', 
              response:'',
              note:'',
              createdBy:{}, 
          },
          accountingDetails:{
              date:moment.unix(),
              dateStr:moment().format('YYYY-MM-DD'), 
              status: '', 
              response:'',
              note:'',
              createdBy:{}, 
          },            
        },
      
        checkCustomerID:false,
        checkSystem:false,
      n_required:0,
      n_true:0,
      r:0,

      items: [
        {
          text: "Elenco progetti",
          href: "/projects/list-view",
        },
        {
          text: "Dettaglio Progetto",
          href: `/projects/detail/${this.$route.params.id}`,
        },
        {
          text: "Forniture e Prodotti",
          active: true,
        },
      ],



    };
  },
    validations: {
      data: {
        source: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        customerCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemSubCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        fiscalBenefit: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
      },
    },
  mounted() {
    this.getItem()
  },
  beforeMount(){
    if (this.$route.name=='warehouse-supply-products'){
      this.items = [
        {
          text: "Fornitura commesse",
          href: "/warehouse/projects/supplies",
        },
        {
          text: "Forniture e Prodotti",
          active: true,
        },
      ];
    }
  },

  methods: {
    ...mapActions('layout', ['changeLayoutType', ]),
    axiosInterceptor(){
      axios.interceptors.response.use(
            (response) => {
                
                return response;
            },
            (error) => {
                if (error.response && error.response.status === 401) {
                  Swal.fire({
                    title:"Accesso non autorizzato",
                    text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:3500,
                  })
                    this.$router.push('/login')
                    if(localStorage.getItem('tk')){
                      localStorage.removeItem('tk')
                    }
                    return Promise.reject('Unauthorized access');
                } else if (error.response && error.response.status  === 404) {
                    Swal.fire({
                      title:"Nessun risultato trovato",
                      text:"Mi spiace, nessun risultato trovato",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('The server cannot find the requested resource');
                }  else if (error.response && error.response.status  === 412) {
                  Swal.fire({
                    title:"Credenziali mancanti",
                    text:"Inserisci delle credenziali valide, per favore.",
                    icon:"warning",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              } else if (error.response && error.response.status  === 423) {
                  Swal.fire({
                      title:"Risorsa non accessibile",
                      text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:3000,
                    })
                  this.$router.push('/')            
                  return Promise.reject('The server cannot find the requested resource');
                }
                else if (error.response && error.response.status  === 500) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Internal Server Error');
                }
                else if (error.response && error.response.status  === 502) {
                    Swal.fire({
                      title:"Errore Server",
                      text:"Mi spiace, ma riscontriamo degli errori sul server.",
                      icon:"error",
                      showCancelButton:false,
                      showConfirmButton:false,
                      timer:2000,
                    })
                    return Promise.reject('Bad Gateway');
                }
                else if (error.response && error.response.status  === 503) {
                  Swal.fire({
                    title:"Servizio non disponibile",
                    text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                    return Promise.reject('Service Unavailable');
                } else {
                  console.log(error.response.status )
                  Swal.fire({
                    title:"Errore imprevisto",
                    text:"Ops! si è verificato un problema...riprova, per favore.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  
                }

            }
          );
    },
    convertTmpToDate(val){
        return moment.unix(val).format('LLL')
    },
    filterOptions(val){
        switch(val){
          case "azienda":
            this.optionsFiscalBenefit = this.optionsFSBusiness
            break;
          case "privato":
            this.optionsFiscalBenefit = this.optionsFSPrivate
            break;
          case "pubblico":
            this.optionsFiscalBenefit = this.optionsFSPA
            break;
        }        
    },    
    filterOptionsSystem(val){
      
        switch(val){
          case "acqua_calda_sanitaria":
            this.optionsSystems = this.optionsSystemsACS
            break;
          case "climatizzazione":
            this.optionsSystems = this.optionsSystemsClima
            break;
          case "edilizia":
            this.optionsSystems = this.optionsSystemsED
            break;
          case "energia_elettrica":
            this.optionsSystems = this.optionsSystemsEL
            break;
        }   
         
    },    
    deleteRecord(ele) {
      ele.target.parentElement.parentElement.remove();
    },
    cambiaOpzioneBeneficio(){
        let privato = [
          {"value":"sconto-fattura","text":"Sconto in fattura"},
          {"value":"conto-termico","text":"Conto Termico"},
          {"value":"nessuno","text":"Nessuno"},
        ]
        let azienda = [
          {"value":"credito-imposta","text":"Credito d'imposta"},
          {"value":"sconto-fattura","text":"Sconto in fattura"},
          {"value":"nessuno","text":"Nessuno"},
        ]    
       
      if (this.project.tipologiaCommessa =='privato'){
        this.opzioneBeneficio = privato
      } else {
        this.opzioneBeneficio = azienda
      }   
    },
    submitForm(){
     
      document.getElementById("genDataForm").submit();
    },
    getParent(){
      this.getItem()
      if (localStorage.getItem("prjTab")) {
                setTimeout(() => {
                document.getElementById(localStorage.getItem('prjTab')).click()
            }, 500);
      }    
    },
    checkDocuments(array){
      if (array){
        let count = array.length
        let checkTrue = array.filter(function(el){
          return el.exist == true
        })
        
        if (count > checkTrue.length ){
          this.r = count - checkTrue.length
        } 
        this.n_required = count
        this.n_true = checkTrue

        return true
      } else {
        return false
      }

    },
    getItem() {
      this.data = {}
      this.axiosInterceptor()
      axios.get(`${this.UrlServer}projects/${this.$route.params.id}`, {headers: { authorization:this.tk}}).then((response) => {
          if (response.data) {
              this.data = response.data[0]
              if (!this.data.accountingDetails.contract){
                this.data.accountingDetails.contract = false
              }
              if(!this.data.customerDetails){
                this.data.customerDetails = this.customerDetails
              }

              if (response.data[0].customerCategory){
                  this.filterOptions(response.data[0].customerCategory)
                  this.filterOptionsSystem(response.data[0].systemCategory)

                  if (!response.data[0].customerID){
                      this.checkCustomerID = false
                      //this.showMainCustomer = true
                  } else {
                      this.checkCustomerID = true
                      //this.showMainCustomer = false                    
                  }
                  if (!response.data[0].systems || response.data[0].systems.length == 0){
                      this.checkSystem = false
                      //this.showMainSystems = true
                  } else {
                      this.checkSystem = true
                      //this.showMainSystems = false                    
                  }
                  if (!response.data[0].surveyDetails){
                    this.data.surveyDetails = {
                      date:moment.unix(),
                      dateStr:moment().format('YYYY-MM-DD'), 
                      status: '', 
                      response:'',
                      note:'',
                      createdBy:{},                       
                    }
                  }
                  if (!response.data[0].accountingDetails){
                    this.data.accountingDetails = {
                      date:moment.unix(),
                      dateStr:moment().format('YYYY-MM-DD'), 
                      status: '', 
                      response:'',
                      note:'',
                      createdBy:{},                       
                    }
                  }
                  if (!response.data[0].documentsRequired){
                    this.data.documentsRequired = []
                  }                  
              }
              
          }        
          
      })
    },
    init(){
      this.axiosInterceptor()
      axios.post(`${this.UrlServer}worksites/register`, this.worksite, {headers: { authorization:this.tk}}).then((response) => {
        if(response.data) {
          this.getItem()
          Swal.fire( this.$t("t-msg-worksite-tab-created-title") , this.$t("t-msg-worksite-tab-created-content"), "success");
        }
      })
    },
    getDocs() {
      this.axiosInterceptor()
        axios.get(`${this.UrlServer}settings/all/documents/list/${this.data.systemSubCategory}`, {headers: { authorization:this.tk}}).then((response) => {
          if (response.data) {
            this.data.documentsRequired = response.data
            this.updateItem()
          }        
          
      })
    },
    updateItem(){
       // this.submitted = true;
        this.v$.$touch();
        this.data.createdBy = this.datastore.auth.currentUser
        this.axiosInterceptor()
        axios.put(`${this.UrlServer}projects/${this.$route.params.id}`, this.data, {headers: { authorization:this.tk}} )
          .then(()=>{
            
          })
      },
    updateDoc(tag){
      let val = document.getElementById(tag).value
      console.log(val)

    },
    updateW(){
     this.axiosInterceptor()
     axios.put(`${this.UrlServer}worksites/${this.data.worksite[0]._id}`, this.data.worksite[0], {headers: { authorization:this.tk}})
     .then(() => {
     })
   }, 
  },
  components: {
    gestioneProdottiProgetto,
    Layout,
    PageHeader,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1" >
            <template v-if="data">
                <span class="mdi mdi-24px mdi-account-details pr-5 "></span>
                <template v-if="data.customerDetails">{{ data.customerDetails.ragSoc }}</template>
                 - Localizzazione: 
                 <template v-if="data.worksite!=null">
                     <template v-if="data.worksite.length>0">
                        {{ data.worksite[0].address }} {{ data.worksite[0].city }}
                     </template>
                </template>
            </template>
            </h4>
            <div class="flex-shrink-0">
                <template v-if="data">
                    Tipo impianto: 
                   <strong> {{ data.systemSubCategory }}</strong>
                </template>
            </div>
          </div>
        </div>
      </div>
    </div>     
    <div class="row" v-if="data.accountingDetails">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <div class="card-title mb-0 flex-grow-1">
                <div class="row">

                  <div class="col-lg-3">
                    <div class="input-group mb-3">

                      <div class="input-group-prepend">
                          <template v-if="data.accountingDetails.contract">
                            <span class="mdi mdi-24px mdi-check pr-5 "></span>
                            <span class="pr-5">Gestione Forniture e Prodotti</span> 
                          </template>
                          <template v-else>
                            <span class="mdi mdi-24px mdi-pause pr-5 "></span>
                            <span class="pr-5">Gestione Forniture e Prodotti</span> 
                            <b-badge variant="warning" class="badge-label" ><i class="mdi mdi-alert-medium"></i> 
                           
                              &nbsp;<span class="pr-2">In attesa conferma stipula contratto.</span>
                            </b-badge>
                          </template>

                      </div>

                    </div>        
                  </div>   
                  <div class="col-lg-3">
                    <div class="input-group mb-3">

                    </div>        
                  </div>   
                </div>
            </div>
            <div class="flex-shrink-0">
              <div
                class="form-check form-switch form-switch-right form-switch-md"
                v-if="data.accountingDetails.contract"
              >
               <template v-if="$route.name!=='warehouse-supply-products'">
                <button type="button" class="btn btn-secondary" title="Apri scheda di lavorazione" @click="$router.push('/projects/detail/plan/'+$route.params.id)"> <i class="bx bx-edit-alt"></i></button>
               </template>              
              &nbsp;
                <label for="FormSelectSystem" class="form-label text-muted"
                  >Mostra</label
                >
                <input
                  class="form-check-input code-switcher"
                  type="checkbox"
                  id="FormSelectSystem"
                  v-model="showTechProgect"
                />
              </div>
            </div>
          </div>
          <div class="card-body" v-if="showTechProgect">
             <gestioneProdottiProgetto type="project" :projectID="data._id" :data="data"  />  
          </div>
        </div>
      </div>

    </div>     
  </Layout>
</template>
